<template>
   <div class="file-upload-container">
    <h1>文件上传下载</h1>
    <input type="file" @change="handleFileUpload" class="file-input" />
    <button @click="uploadFile" class="btn" :disabled="uploading">上传文件</button>
    <button @click="downloadFile" :disabled="!fileId" class="btn">下载文件</button>
    <p v-if="fileId" class="file-id">文件ID: {{ fileId }}</p>
    <p v-if="uploading" class="upload-status">正在上传...</p>
  </div>
</template>

  <script>
 import axios from '../api/axios';

 export default {
  data() {
    return {
      file: null,
      fileId: null,
      uploading: false,
      fileName: null
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    uploadFile() {
      if (this.file) {
        this.uploading = true;
        const formData = new FormData();
        formData.append('file', this.file);

        axios.post('/file/upload', formData, {
          timeout: 3000000,
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted); // 可以在控制台看到上传进度
          }
        })
        .then(response => {
          this.fileId = response.data.data.fileName;
          this.fileName = response.data.data.fileName;
          this.uploading = false;
          alert('文件上传成功');
        })
        .catch(error => {
          console.error('上传失败', error);
          this.uploading = false;
          alert('文件解析失败');
        });
      } else {
        alert('请选择文件');
      }
    },
    downloadFile() {
      if (this.fileId) {
        const link = document.createElement('a');
        link.href = `${process.env.VUE_APP_API_BASE_URL}/file/down/${this.fileId}`;
        link.setAttribute('download', this.file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
  </script>

<style>
  .file-upload-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }

  .file-input {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  .btn {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    outline: none;
  }

  .btn[disabled] {
    background-color: #6c757d;
  }

  .file-id {
    margin-top: 10px;
    color: #333;
  }

  .upload-status {
    margin-top: 10px;
    color: #333;
  }
</style>
